<template>
    <div class="w-full h-screen flex items-center">
        <img src="@/assets/images/WEB2.jpg" class="fixed h-screen w-full  z-10"/>
       <Modal v-show="this.show_message_modal" 
        v-bind:display="this.show_message_modal"
        :error="this.error"
        :success="this.success"
        z_value="z-50"
        @positiveClick="closed"
        :positiveButton="'OK'"
        :default_width="'max-w-xl'"
        id='vote_modal'>
        <div class="text-xl p-2 flex w-full font-bold justify-center" v-if="success" >CONGRATULATIONS ON FINISHING YOUR APPLICATION! HERE IS YOUR REFERENCE NO.</div>
        <div class="w-full p-5 flex flex-col text-2xl font-bold text-theme-primary">
            {{ this.modal_message}}
        </div>
         <div class="text-xl p-2 flex w-full font-bold justify-center" v-if="success" >PLEASE KEEP YOUR LINES OPEN, OUR MEMBERSHIP REPRESENTATIVE WILL CONTACT YOU.</div>
        </Modal>
        <Modal v-show="show_rate_modal" 
        v-bind:display="show_rate_modal"
        :error="this.error"
        :success="this.success"
        z_value="z-50"
        @positiveClick="submitRate()"
        :positiveButton="'SUBMIT'"
        :default_width="'max-w-3xl'"
        id='vote_modal'>
        <div class="text-2xl p-5 flex w-full font-bold justify-center text-white bg-theme-primary rounded-t-md " >RATE YOUR EXPERIENCE</div>
        <div class="w-full p-3 flex flex-col text-left text-lg font-bold ">
            <div>
                1. Sa iyong pananaw, mas nadalian kaba sa paraan ng pag apply mo online kaysa sa pumunta ka sa PAFCPIC?
            </div>
           <div class="flex flex-row pt-2 space-x-10">
            <CheckBox id="Q1_yes" ref="Q1_yes" class="text-left pt-2 pl-2 xs:pl-2" @changed="selected('yes','Q1_yes')" :rating="true" :disabled="disable_q1_yes" :checkbox_width="25">
                <span v-text="'OO'" class="font-thin text-lg xs:text-md"/>
            </CheckBox>
            <CheckBox id="Q1_no" ref="Q1_no" class="text-left pt-2 pl-2 xs:pl-2" @changed="selected('no','Q1_no')" :rating="true" :disabled="disable_q1_no" :checkbox_width="25">
                <span v-text="'HINDI'" class="font-thin text-lg xs:text-md"/>
            </CheckBox>
           </div>
        </div>
        <div class="w-full p-5 flex flex-col text-left text-lg font-bold ">
            <div>
                2. I-rirekomenda mo ba ang PAFCPIC Online Membership Application (OMA)?
            </div>
           <div class="flex flex-row pt-2 space-x-10">
            <CheckBox id="Q2_yes" ref="Q2_yes" class="text-left pt-2 pl-2 xs:pl-2" @changed="selected('yes','Q2_yes')" :rating="true" :disabled="disable_q2_yes" :checkbox_width="25">
                <span v-text="'OO'" class="font-thin text-lg xs:text-md"/>
            </CheckBox>
            <CheckBox id="Q2_no" ref="Q2_no" class="text-left pt-2 pl-2 xs:pl-2" @changed="selected('no','Q2_no')" :rating="true" :disabled="disable_q2_no" :checkbox_width="25">
                <span v-text="'HINDI'" class="font-thin text-lg xs:text-md"/>
            </CheckBox>
           </div>
           <div class="text-red-500 font-md font-thin" v-if="show_rating_error">Sagutan ang survey</div>
        </div>
       </Modal>
        <Modal v-show="this.show_update_modal" 
        v-bind:display="this.show_update_modal"
        :error="this.error"
        :success="this.success"
        z_value="z-50"
        @positiveClick="closedUpdate"
        :positiveButton="'OK'"
        :default_width="'max-w-xl'"
        id='vote_modal'>
        <div class="w-full p-3 flex flex-col text-2xl font-bold text-theme-primary">
            {{ this.modal_message}}
        </div>
        </Modal>

        <Modal v-show="this.show_email_modal" 
        v-bind:display="this.show_email_modal"
        :error="this.error"
        :success="this.success"
        z_value="z-50"
        @positiveClick="updateRecord('email')"
        :positiveButton="'OK'"
        @negativeClick="closeUpdating"
        :negativeButton="'CANCEL'"
        :default_width="'max-w-xl'"
        id='vote_modal'>
        <div class="text-xl p-2 flex w-full font-bold justify-center text-theme-primary">Please Enter Your Email</div>
        <div class="w-full p-5 flex flex-col text-2xl font-bold text-theme-primary">
            <TextField nopaste :id="'email'" ref="email" class="flex-grow w-full" @changed="update"/>
            <span v-if="!valid_email && valid_email!=null" v-text="'Invalid Email'" class="text-red-500 text-sm text-left"/>
        </div>
        </Modal>
        <Modal v-show="this.show_mobile_modal" 
        v-bind:display="this.show_mobile_modal"
        z_value="z-50"
        @positiveClick="updateRecord('mobile')"
        :positiveButton="'OK'"
        @negativeClick="closeUpdating"
        :negativeButton="'CANCEL'"
        :default_width="'max-w-xl'"
        id='vote_modal'>
        <div class="text-xl p-2 flex w-full font-bold justify-center text-theme-primary">Please Enter Your Mobile No.</div>
        <div class="w-full p-5 pt-2 flex flex-col text-2xl font-bold text-theme-primary">
            <TextField nopaste nospecial :max_length="9" ref="mobile" :id="'mobile'" type='mobile'  @changed="update"/>
            <span v-if="!valid_mobile && valid_mobile!=null" v-text="'Invalid Mobile No.'" class="text-red-500 text-sm text-left"/>
        </div>
        </Modal>
        
        <LoadingControl v-show="loading" :loading="loading"/>
        <div class="m-auto bg-white  flex flex-col xs:border-0 border rounded  xs:m-2 w-full max-w-md p-3 space-y-2 z-30">
            <span v-text="'PLEASE ENTER THE FOLLOWING'" class="font-bold text-2xl xs:text-center "/> 
            <div class="pt-2 flex flex-col">
                <span v-text="'Mobile OTP'" class="font-bold text-xl "/>
                <div class="flex flex-col items-center w-full space-x-1">
                    <div class="w-full flex flex-row place-items-center">
                        <TextField nopaste nospecial :id="'mobOtp'" class="w-full" @changed="update"/>
                        <Icon id="pencil"  class="w-full" :source="getSource()" :width="25" v-show="mobile_validated"/>
                    </div>
                    <div class="flex flex-row w-full justify-left pt-2 space-x-1" v-show="mobile_validated?false:true">
                        <Button :id="'submit'" @clicked="validate_phone" :button_text="'VERIFY'" class="max-w-xxs w-full" v-show="mobile_validated?false:true"/>
                        <Button :id="'submit'" @clicked="resendMobile" :button_text="'RESEND'" v-if="!disable_mobile_button" :disabled="disable_mobile_button" class="w-full max-w-xxs" v-show="mobile_validated?false:true"/>
                        <Button :id="'submit'" @clicked="changeNumber" :button_text="'CHANGE NUMBER'" v-if="!disable_mobile_button" :disabled="disable_mobile_button"  class="w-full max-w-xxs" v-show="mobile_validated?false:true"/>
                    </div>
                    <span v-if="show_resend_mobile && !show_resend_mobile" class="text-theme-primary text-md" v-text="'Please wait ' + request_timer_mobile + ' Resend OTP' "/>
                </div>
            </div>
            <div>
                <span v-text="'Email OTP'" class="font-bold text-xl"/>
                 <div class="flex flex-col items-center w-full space-x-1">
                    <div class="w-full flex flex-row place-items-center">
                        <TextField nopaste nospecial :id="'emailOtp'" class="flex-grow w-full" @changed="update"/>
                        <Icon id="pencil" :source="getSource()" :width="25" v-show="email_validated"/>
                    </div>
                    <div class="flex flex-row w-full justify-left pt-2 space-x-1" v-show="email_validated?false:true">
                        <Button :id="'submit'" @clicked="validate_email" :button_text="'VERIFY'" class="max-w-xxs" v-show="email_validated?false:true"/>
                        <Button :id="'submit'" @clicked="resendEmail" :button_text="'RESEND'" v-if="!disable_email_button" :disabled="disable_email_button" class="max-w-xxs" v-show="email_validated?false:true"/>
                        <Button :id="'submit'" @clicked="changeEmail" :button_text="'CHANGE EMAIL'" v-if="!disable_email_button" :disabled="disable_email_button" class="max-w-xxs" v-show="email_validated?false:true"/>
                    </div>
                 </div>
                 <span v-if="!show_resend_mobile && show_resend_mobile" class="text-theme-primary text-md" v-text="'Please wait ' + request_timer_email + ' Resend OTP' "/>
            </div>
            <div>
                <span v-if="show_resend_email && show_resend_mobile" class="text-theme-primary text-md" v-text="'Please wait ' + request_timer_email + ' Resend OTP' "/>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import TextField from '@/components/controls/TextBoxField.vue';
import Button from '@/components/controls/ButtonControl.vue';
import Modal from "@/components/controls/ModalControl.vue";
import memberApplication from '@/services/Member';
import LoadingControl from '@/components/controls/LoadingControl.vue';
import Otp from '@/services/OTP';
import Icon from "@/components/controls/Icon.vue"
import User from '@/services/User';
import CheckBox from '@/components/controls/CheckBox.vue';

export default defineComponent({
    components:{
        TextField,
        LoadingControl,
        Button,
        Modal,
        Icon,
        CheckBox
    },
    data(){
        return {
            error:false,
            success:false,
            request_timer_email:0,
            request_timer_mobile:0,
            show_message_modal:false,
            show_update_modal:false,
            modal_message:'',
            post_back_ref:'',
            emailOtp:'',
            mobOtp:'',
            email:'',
            mobile:'',
            q1_answer:'',
            q2_answer:'',
            disable_q1_yes:false,
            disable_q1_no:false,
            disable_q2_yes:false,
            disable_q2_no:false,
            show_rating_error:false,
            show_rate_modal:false,
            timer_enabled_email:false,
            timer_enabled_mobile:false,
            disable_email_button:true,
            disable_mobile_button:true,
            show_resend_mobile:false,
            show_resend_email:false,
            loading:false,
            confirmed:false,
            mobile_validated:false,
            email_validated:false,
            message:"",
            valid_mobile:null,
            valid_email:null,
            creds_verified:true,
            show_email_modal:false,
            show_mobile_modal:false,
        }
    },
    mounted(){
        // this.show_message_modal= true;
        this.timer_enabled_email = true;
        this.request_timer_email = 120;
        this.timer_enabled_mobile = true;
        this.request_timer_mobile = 120;
    },
    methods:{
        selected(answer,code){
        let that = this;
        let ref = that.$refs;
            if(code=="Q1_yes" && ref.Q1_yes.status==true) {
                that.disable_q1_yes = true;
                that.disable_q1_no = false;
                ref.Q1_no.status =false; 
                that.q1_answer = "YES";
            }else if(code=="Q1_no" && ref.Q1_no.status==true) {
                that.disable_q1_yes = false;
                that.disable_q1_no = true;
                ref.Q1_yes.status =false;
                that.q1_answer = "NO";
            }
            if(code=="Q2_yes" && ref.Q2_yes.status==true) {
                that.disable_q2_yes = true;
                that.disable_q2_no = false;
                ref.Q2_no.status =false;
                that.q2_answer = "YES"; 
            }else if(code=="Q2_no" && ref.Q2_no.status==true) {
                 that.disable_q2_yes = false;
                that.disable_q2_no = true;
                ref.Q2_yes.status =false;
                that.q2_answer = "NO";
            }            
        },
       closed() {
        if(this.success) {
           this.error = false;
           this.success = false;
           this.show_rate_modal = true;
        } 
        else
        {
            this.show_message_modal = false;
            this.error = false;
            this.success = false;
        }
       },
       
       closedUpdate(){
        this.show_update_modal = false;
        this.error = false;
        this.success = false;
       },
       closeUpdating(){
            this.show_email_modal=false;
            this.show_mobile_modal=false;
            this.error = false;
            this.success = false;

       },
       getSource() {
                return 'icons/papsi_success_icon.svg';
        },
       update(e) {
          let key = e.key;
          let that = this;

          that.error_message = false;
          that[key] = e.value;
          // this.enableButton();
        },
        submitRate() {
            let that = this;
            if(that.q1_answer=="" || that.q2_answer=="") {
                that.show_rating_error = true;
            } else {
             that.loading = true;

             let payload= {
                reference:that.post_back_ref,
                q1_answer:that.q1_answer,
                q2_answer:that.q2_answer,
             }
            // console.log(payload);
            memberApplication.submitRates(payload).then(function() {
                that.error = false;
                that.success = false;
                that.loading = false;
                that.$router.push('/');
                that.show_rate_modal = false;
            }).catch(function(error){
                that.error = true;
                that.success = false;
                that.loading = false;
                that.show_message_modal = true;
                that.modal_message = error.response.data.message;
            });  
                
            }
        },
        updateRecord(type){
            let that = this;

            if(type=='email'){
                that.validate_dups("email");
            } else {
                that.validate_dups("mobile");
            }
        },
        validate_dups(id) {
          let that = this;
          let payload;
          that.loading = true;
          let store = that.$store;
          let state = store.state;
          let membership_details = state.membership_details;

          if(id =='mobile') {
            payload = {
              type:3,
              value:'+63'+that.mobile,
            }
            that.show_resend_mobile = false;
          }
          if(id=='email') {
              payload = {
              type:2,
              value:that.email,
            }
            that.show_resend_email = false;
          }
          User.testData(payload).then(function(){
            window.scrollTo(0, top);
            that.loading = false;
            that.success = true;
            that.error = false;
            that.show_update_modal = true;
            if(id =='mobile'){
                that.valid_mobile = true;
                that.show_mobile_modal = false;
                membership_details.mobile = '+63'+that.mobile;
                that.modal_message ="Successfully Changed Mobile No.";
                that.resendMobile();
            }else{
                that.valid_email = true;
                that.show_email_modal = false;
                membership_details.email = that.email;
                that.modal_message ="Successfully Changed Email";
                that.resendEmail();
            }
          }).catch(function() {
            that.loading = false;
            if(id =='mobile'){
                that.valid_mobile = false;
            }else{
                that.valid_email = false;
            }
          });
        },
        pushApplication(){
            let that = this;
            let store = that.$store;
            let state = store.state;
            let membership_details = state.membership_details;
            that.loading = true;
             
            let payload = {
                ref_num:membership_details.ref_account,
                ref_name:membership_details.ref_name,
                br_code:membership_details.pso,
                preferred_pso:membership_details.pso,
                first_name:membership_details.first_name,
                last_name:membership_details.last_name,
                middle_name:membership_details.middle_name,
                gender:membership_details.gender,
                date_of_birth:membership_details.birth_date,
                civil_status:membership_details.civil_status,
                serial_no:(membership_details.afp_serial_number!= null && membership_details.afp_serial_number != "") ? membership_details.afp_serial_number :"??????",
                tin:membership_details.tin,
                mobile:membership_details.mobile,
                email:membership_details.email,
                signature:membership_details.signature,
                id_front:membership_details.front,
                id_back:membership_details.back,
                father_fn:membership_details.fafirst_name!=null?membership_details.fafirst_name:'',
                father_ln:membership_details.falast_name!=null?membership_details.falast_name:'',
                father_mn:membership_details.famiddle_name!=null ?membership_details.famiddle_name:'',
                mother_fn:membership_details.mofirst_name,
                mother_ln:membership_details.molast_name,
                mother_mn:membership_details.momiddle_name!=null?membership_details.momiddle_name:'',
                spouse_fn:membership_details.spfirst_name,
                spouse_ln:membership_details.splast_name,
                spouse_mn:membership_details.spmiddle_name!=null?membership_details.spmiddle_name:'',
                address:membership_details.resstreet_address,
                province:membership_details.resprovince,
                city:membership_details.rescity,
                zipcode:membership_details.reszip_code,
                alt_address:membership_details.altstreet_address,
                alt_province:membership_details.altprovince,
                alt_city:membership_details.altcity,
                alt_zipcode:membership_details.altzip_code,
                empstatus:membership_details.employee_status,
                suffix:membership_details.suffix,
                brgy:membership_details.resbrngy,
                alt_brgy:membership_details.altbrngy,
                birth_place: membership_details.place_of_birth,
                citizenship:membership_details.citizenship,
                branch_service:membership_details.branch_of_service,
                sss:membership_details.sss_number,
                gsis:membership_details.gsis_number,
                schedule:membership_details.schedule,
                beneficiary:JSON.stringify(membership_details.beneficiaries),
                share:membership_details.sc,
                docs:JSON.stringify(membership_details.uploaded_requirements),
                sc:membership_details.sc_agree,
                wcp:membership_details.wcp_agree,
                cisa:membership_details.cisa_agree,
                snap:membership_details.snap,
                pmes_dt: membership_details.pmes_dt,
                spouse:JSON.stringify(membership_details.spouse),
                rank:membership_details.rank,
                sponsor_nr:membership_details.sponsor_nr,
                sponsor_name:membership_details.sponsor_name,
                sponsor_id:membership_details.sponsor_id,
                interview_mode:membership_details.interview_mode,

            }
       
                that.loading = true;
                memberApplication.postApplication(payload).then(function(response) {
                if(response.data.status == 200) {
                    that.$emit('hideloader');
                    that.error = false;
                    that.success = true;
                    that.loading = false;
                    that.show_message_modal = true;
                    that.post_back_ref = response.data.data.reference;
                    that.modal_message = response.data.data.reference;
                    membership_details.first_name = null;
                    membership_details.last_name = null;
                    membership_details.middle_name = null;
                    membership_details.rank = null;
                    membership_details.birth_date = null
                    membership_details.place_of_birth = null;
                    membership_details.gender = null;
                    membership_details.civil_status = null;
                    membership_details.afp_serial_number = null;
                    membership_details.branch_of_service = null;
                    membership_details.employee_status = null;
                    membership_details.tin = null;
                    membership_details.mobile = null;
                    membership_details.email = null;
                    membership_details.re_email = null;   
                    membership_details.spfirst_name = null;
                    membership_details.splast_name = null;
                    membership_details.spmiddle_name = null;
                    membership_details.mofirst_name = null;
                    membership_details.molast_name = null;
                    membership_details.momiddle_name = null;
                    membership_details.fafirst_name = null;
                    membership_details.falast_name = null;
                    membership_details.famiddle_name = null;
                    membership_details.front = null;
                    membership_details.back = null;
                    membership_details.signature = null;
                    membership_details.confirmed = null;
                    membership_details.interview_date = null;
                    membership_details.pso = null;
                    membership_details.resstreet_address = null;
                    membership_details.reszip_code = null;
                    membership_details.resprovince = null;
                    membership_details.rescity = null;
                    membership_details.resbrngy = null;
                    membership_details.altstreet_address = null;
                    membership_details.altzip_code = null;
                    membership_details.altprovince = null;
                    membership_details.altcity = null;
                    membership_details.altbrngy = null;
                    membership_details.altaltstreet_address = null;
                    membership_details.ref_num =null;
                    membership_details.ref_name=null;
                    membership_details.spouse = [];
                    membership_details.beneficiaries =[];
                    membership_details.sc = null;
                    membership_details.sc_agree = null ;
                    membership_details.wcp_agree = null;
                    membership_details.cisa_agree = null;
                    membership_details.snap = null;
                    membership_details.pmes_dt = null;
                    membership_details.rank = null;
                    membership_details.sponsor_nr = null;
                    membership_details.sponsor_name = null;
                    membership_details.sponsor_id = null;
                }
            }).catch(function(error){
                that.error = true;
                that.success = false;
                that.loading = false;
                that.show_message_modal = true;
                that.modal_message = error.response.data.message;
            });   
        },
        validate_phone(){
            let that = this;
            let store = that.$store;
            let state = store.state;
            let membership_details = state.membership_details;
            that.loading = true;
            // that.timer_enabled_mobile= true;
            // that.request_timer_mobile = 120;
            let payload_mobile;
             payload_mobile = {
                    otp:that.mobOtp,
                    type:12,
                    mobile:membership_details.mobile
                };
            Otp.validate(payload_mobile).then(function() {
                   that.mobile_validated = true;
                   that.loading = false;
                   if(that.mobile_validated && that.email_validated){
                        that.creds_verified = false;
                        that.pushApplication();
                   }
            }).catch(function(error){
                that.error = true;
                that.success = false;
                that.loading = false;
                that.show_message_modal = true;
                that.modal_message = error.response.data.message;
            });
        },
        changeNumber() {
            this.show_mobile_modal = true;
            let that = this;
            let store = that.$store;
            let state = store.state;

            this.$refs.email.curr_val = state.membership_details.email;
        },
        changeEmail() {
            let that = this;
            let store = that.$store;
            let state = store.state;

            this.$refs.mobile.curr_val = state.membership_details.mobile;
            this.show_email_modal = true;
        },
        resendEmail(){
            let that = this;
            let store = that.$store;
            let state = store.state.membership_details;
            let payload;
            that.timer_enabled_email= true;
            that.request_timer_email = 120;
            that.loading = true;
            payload = {
                type:11, 
                email:state.email,
                name :state.first_name, 
                pin:state.email_pin,
            }
           Otp.requestOtp(payload).then(function(response) {
              state.email_pin = response.data.data.pin;
              that.loading = false;
          }).catch(function() {
              that.loading= false;
          });
        },
        resendMobile(){
            let that = this;
            let store = that.$store;
            let state = store.state.membership_details;
            let payload;
            that.loading = true;
            that.timer_enabled_mobile= true;
            that.request_timer_mobile = 120;
            payload = {
                type:12, 
                mobile:state.mobile,
                name :state.first_name, 
                pin:state.phone_pin,
            }
           Otp.requestOtp(payload).then(function(response) {
              state.phone_pin = response.data.data.pin;
              that.loading = false;
          }).catch(function() {
              that.loading= false;
          });
        },
        validate_email(){
            let that = this;
            let store = that.$store;
            let state = store.state;
            let membership_details = state.membership_details;
            that.loading = true;
            let payload_email;

            payload_email = {   
                    otp:that.emailOtp,
                    type:11,
                    email:membership_details.email
                };
            Otp.validate(payload_email).then(function() {
                that.email_validated = true;  
                that.loading = false; 
                if(that.mobile_validated && that.email_validated){
                    that.creds_verified = false;
                    that.pushApplication();
                } 
            }).catch(function(error){
                that.error = true;
                that.success = false;
                that.loading = false;
                that.show_message_modal = true;
                that.modal_message = error.response.data.message;
            });
        },
        submit() {
            this.pushApplication();
        },
    },
    watch:{
    request_timer_email: {
      handler(value) {
        if (value > 0 && this.timer_enabled_email) {
          this.disable_email_button = true;
          this.show_resend_email = true;
          setTimeout(() => {
            this.request_timer_email = this.request_timer_email - 1;
          }, 1000);
        } else {
          this.disable_email_button = false;
           this.show_resend_email = false;
          this.timer_enabled_email = false; 
        }
      },
      immediate: true
    },
    request_timer_mobile: {
      handler(value) {
        if (value > 0 && this.timer_enabled_mobile) {
          this.disable_mobile_button = true;
          this.show_resend_mobile = true;
          setTimeout(() => {
            this.request_timer_mobile = this.request_timer_mobile - 1;
          }, 1000);
        } else {
          this.show_resend_mobile = false;
          this.disable_mobile_button = false;
          this.timer_enabled_mobile = false; 
        }
      },
      immediate: true
    },
    }
})
</script>
<style scoped>

</style>
